<template>
  <div class="animated fadeIn">
    <b-card body-class="p-0">
      <b-card-header>
        <b-row>
          <b-col cols="8">
            <h4 class="mt-2">
              {{ $t('payments.titles.balanceHistory') }}
            </h4>
          </b-col>
          <b-col cols="4" class="text-right mt-1">
            <b-button
              v-if="findGroups('ACL:BUTTON:RESET_BALANCE')"
              variant="warning"
              @click="resetBalance"
            >
              {{ $t('payments.buttons.resetBalance') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="p-0 wallet-table-format">
        <custom-data-table
          ref="balance_history"
          :fields="fields"
          :filters="{}"
          :query="drivers_transactions"
          :query-variables="variables"
          :fixed-filters="fixedFilters"
          :sort="sort"
          :cache="false"
          query-node="getBalanceHistory"
        >
          <template slot="type" slot-scope="props">
            <big-badge :variant="setTypeFormat(props.rowData)">
              {{ setParseType(props.rowData.type) }}
            </big-badge>
          </template>
          <template slot="description" slot-scope="props">
            <strong>{{ setTransactionInfos(props.rowData.transaction, 'description', 'payments.balance.fallbackDescription') }}</strong>
          </template>
          <template slot="internal_comments" slot-scope="props">
            {{ setTransactionInfos(props.rowData.transaction, 'internal_comments', 'payments.balance.fallbackJustification') }}
          </template>
          <template slot="balance_amount" slot-scope="props">
            {{ setPriceFormat($t('payments.labels.currency'), props.rowData.balance_amount) }}
          </template>
          <template slot="balance_in_use" slot-scope="props">
            <strong :class="`text-${setTypeFormat(props.rowData)}`"> {{ setPriceFormat($t('payments.labels.currency'), props.rowData.value) }} </strong>
          </template>
          <template slot="inserted_at" slot-scope="props">
            {{ props.rowData.inserted_at | moment('DD/MM/YYYY') }}
          </template>
          <template slot="invoice" slot-scope="props">
            <span :id="setIdTransaction(props.rowData.transaction)" style="font-size: 12px">
              {{ setIdTransaction(props.rowData.transaction) }}
            </span>
            <b-badge
              v-if="canCopy" 
              variant="primary"
              block
              title="copy"
              @click="copyId(props.rowData.transaction.invoice)"
            >
              <i class="fa fa-files-o" aria-hidden="true" /> Copy
            </b-badge>
          </template>
        </custom-data-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import { formatType, parseType } from '@utils/balanceHistory';
import { makeMutation } from "@graphql/middleware";
import BigBadge from '@components/shared/big-badge';
import CustomDataTable from '@components/CustomDataTable';
import BALANCE_HISTORY from '@graphql/transaction/queries/history.gql';
import RESET_BALANCE_PRE_PAYMENT from '@graphql/transaction/mutations/resetBalancePrePayment.gql';
import { mapGetters } from 'vuex';

export default {
  name: 'BalanceHistory',
  components: {
    CustomDataTable,
    BigBadge,
  },
  data() {
    return {
      variables: {
        driver: this.$route.params.id
      },
      fields: [
        {
          name: 'type',
          title: this.$t('payments.tables.type'),
        },
        {
          name: 'description',
          title: this.$t('payments.tables.baseTransaction'),
        },
        {
          name: 'internal_comments',
          title: this.$t('payments.tables.justification'),
        },
        {
          name: 'inserted_at',
          title: this.$t('payments.tables.heldIn'),
        },
        {
          name: 'balance_in_use',
          title: this.$t('payments.tables.balanceInUse'),
        },
        {
          name: 'balance_amount',
          title: this.$t('payments.tables.balanceInWallet'),
        },
        {
          name: 'invoice',
          title: this.$t('payments.tables.invoice'),
        }
      ],
      sort: [['inserted_at', 'DESC']],
      drivers_transactions: BALANCE_HISTORY,
      canCopy: false
    };
  },
  computed: {
    fixedFilters() {
      return {
        driver: this.$route.params.id
      };
    },
    ...mapGetters('user', ['findGroups'])
  },
  mounted () {
    this.canCopy = !!navigator.clipboard;
  },
  methods: {
    setParseType: parseType,
    setTypeFormat(history) {
      let type = history.type;

      if (!history) return;
      if (!history.type) return 'STATUS_ERROR';
      return formatType(type);
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    setIdTransaction (transaction) {
      return transaction ? transaction.invoice : '';
    },
    setTransactionInfos (transaction, key, fallback) {
      if (transaction && transaction[key]) return transaction[key];
      return this.$t(fallback);
    },
    async copyId (id) {
      await navigator.clipboard.writeText(id);
      this.$snotify.info(this.$t('payments.labels.copySuccess'))
    },
    resetBalance () {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: this.$t('payments.manageTed.alertConfirmation'),
        text: this.$t('payments.manageTed.textConfirmation'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then( result => {
        if (result.value) {
          makeMutation(
            RESET_BALANCE_PRE_PAYMENT,
              {
                driver: this.$route.params.id
              }
            )
              .then(() => {
                this.showSuccessModal()
                this.$refs.balance_history.$_refresh();
              })
              .catch(() => {
                this.showErrorModal()
              })
        }
      })
    },
    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: this.$t('payments.manageTed.textAlertSuccess'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => this.$emit('refresh'))
    },
    showErrorModal() {
      this.$swal({
        type: 'error',
        title: this.$t('payments.manageTed.textAlertError'),
        text: this.$t('payments.manageTed.textError'),
        showConfirmButton: false,
        timer: 8000,
      });
    },
  }
};
</script>

<style>
.vuetable-th-slot-internal_comments {
  width: 320px !important;
}
</style>
