var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "body-class": "p-0" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("payments.titles.balanceHistory")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-1", attrs: { cols: "4" } },
                    [
                      _vm.findGroups("ACL:BUTTON:RESET_BALANCE")
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "warning" },
                              on: { click: _vm.resetBalance },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("payments.buttons.resetBalance")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-body",
            { staticClass: "p-0 wallet-table-format" },
            [
              _c("custom-data-table", {
                ref: "balance_history",
                attrs: {
                  fields: _vm.fields,
                  filters: {},
                  query: _vm.drivers_transactions,
                  "query-variables": _vm.variables,
                  "fixed-filters": _vm.fixedFilters,
                  sort: _vm.sort,
                  cache: false,
                  "query-node": "getBalanceHistory",
                },
                scopedSlots: _vm._u([
                  {
                    key: "type",
                    fn: function (props) {
                      return [
                        _c(
                          "big-badge",
                          {
                            attrs: {
                              variant: _vm.setTypeFormat(props.rowData),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.setParseType(props.rowData.type)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "description",
                    fn: function (props) {
                      return [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.setTransactionInfos(
                                props.rowData.transaction,
                                "description",
                                "payments.balance.fallbackDescription"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "internal_comments",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setTransactionInfos(
                                props.rowData.transaction,
                                "internal_comments",
                                "payments.balance.fallbackJustification"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "balance_amount",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setPriceFormat(
                                _vm.$t("payments.labels.currency"),
                                props.rowData.balance_amount
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "balance_in_use",
                    fn: function (props) {
                      return [
                        _c(
                          "strong",
                          { class: `text-${_vm.setTypeFormat(props.rowData)}` },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    props.rowData.value
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "inserted_at",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("moment")(
                                props.rowData.inserted_at,
                                "DD/MM/YYYY"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "invoice",
                    fn: function (props) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: {
                              id: _vm.setIdTransaction(
                                props.rowData.transaction
                              ),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setIdTransaction(
                                    props.rowData.transaction
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.canCopy
                          ? _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant: "primary",
                                  block: "",
                                  title: "copy",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyId(
                                      props.rowData.transaction.invoice
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-files-o",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" Copy "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }