import { i18next } from "@translate/i18n";

export const formatType = status => {
  switch (status) {
    case  'credit':
      return 'success';
    case 'debit':
      return 'danger';
    default:
      return 'primary';
  }
};

export const parseType = type => {
  switch (type) {
    case 'debit':
      return i18next.t('payments.enumBalanceType.debitBalance');

    case 'credit':
      return i18next.t('payments.enumBalanceType.creditBalance');

    case 'initial':
      return i18next.t('payments.enumBalanceType.initialBalance');
    
    case 'cash_out':
      return i18next.t('payments.enumBalanceType.cashOut');
  }
};
